<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Welcome to VIII
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer
          v-if="!accountCandidates.length"
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="submit"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link to="/forgot-password">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility('password')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- submit button -->
            <b-alert
              v-if="errorMsg"
              variant="danger"
              show
            >
              <div class="alert-body">
                {{ errorMsg }}
              </div>
            </b-alert>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
        <div
          v-else
          class="accountCandidatesContainer"
        >
          <div
            v-for="(candidate, index) in accountCandidates"
            :key="index"
            class="accountCandidate  mb-1"
          >
            <b-button
              block
              variant="primary"
              style="text-transform:capitalize"
              @click="selectAccount(candidate)"
            >
              {{ candidate.memberType }}
            </b-button>
          </div>
        </div>
        <b-card-text class="text-center mt-2">
          <span>New to VIII? </span>
          <b-link :to="{name:'register'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      errorMsg: '',
      accountCandidates: [],
      uniqueKey: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    selectAccount(candidate) {
      const data = {
        unique_id: candidate.unique_id,
        key: this.uniqueKey,
        username: this.userEmail,
      }

      this.$store.dispatch('users/login', data).then(response => {
        if (response.code === 200) {
          localStorage.setItem('token', response.data.token)
          this.$store.commit('setClientInfo', response.data)

          if (response.data.detail.is_init === '0' && response.data.memberType !== 'superuser') {
            // window.location.href = '/init'
            window.location.href = '/'
          } else {
            window.location.href = '/'
          }
        } else {
          this.errorMsg = response.message
        }
      }).catch(err => {
        console.error(err)
      })
    },
    submit() {
      const data = {
        username: this.userEmail,
        password: this.password,
      }
      this.$store.dispatch('users/login', data).then(response => {
        if (response.code === 200) {
          if (response.data.candidates) {
            this.accountCandidates = response.data.candidates
            this.uniqueKey = response.data.key
          } else {
            localStorage.setItem('token', response.data.token)
            this.$store.commit('setClientInfo', response.data)
            window.location.href = '/'
            // && response.data.memberType !== 'superuser'
            // if (response.data.detail.is_init === '0' && response.data.memberType !== 'superuser') {
            //   window.location.href = '/init'
            // } else {
            // }
          }
          const current = new Date().getTime()
          this.$store.commit('SET_LOGIN_TIME', current)
        } else {
          this.errorMsg = response.message
        }
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.accountCandidate {
  text-transform:capitalize;
}
</style>
